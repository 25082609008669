.bingen__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    padding-left: 0;
    padding-top: 2rem;
    margin-top: 4rem;
    padding-right: 0;
}

.bingen__brand img {
    flex: 1;
    max-width: 10px;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 500px) {

    .bingen__brand {
        padding-left: 1.5rem;
        margin-left: 2rem;
        flex-wrap: nowrap;
        justify-content: unset;
        align-items: unset;
    }

    .bingen__brand img {
        max-width: 200px;
        padding-left: 0;
        margin-left: 0;
    }
}