.bingen__blog-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.bingen__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.bingen__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.bingen__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.bingen__blog-container_article-content a {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 800;
    line-height: 35px;

    color: #d6dbee;
}

.bingen__blog-container_article-content a:hover {
    color: #fff;
}

.bingen__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 22px;
    font-weight: 800;
    line-height: 30px;
    margin-right: 0;
    color: #fff;
    margin-bottom: 2rem;
}

.bingen__blog-container_article-content h2 {
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 450;
    line-height: 30px;

    color: #fff;
    margin-bottom: 1rem;
}

.bingen__blog-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .bingen__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25px;
    }
}
