.close-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    margin: 1rem;
    opacity: 0.3;
    cursor: pointer;
}

.close-container:hover {
    opacity: 0.7;
}

.leftright {
    height: 4px;
    width: 25px;
    position: absolute;
    margin-top: 7px;
    background-color: #fff;
    border-radius: 2px;
    transform: rotate(45deg);
    transition: all .3s ease-in;
}

.rightleft {
    height: 4px;
    width: 25px;
    position: absolute;
    margin-top: 7px;
    background-color: #fff;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: all .3s ease-in;
}
